@import "@/assets/scss/var.scss";
.courses {
	font-size: 14px;
	.courses-content {
		display: flex;
		flex-flow: wrap;
		margin-right: -10px;
		margin-left: -10px;
	}
	.course {
		&-item {
			display: flex;
			flex-basis: calc(100% - 10px);
			margin: 10px 5px 0 5px;
			.el-card {
				width: 100%;
			}
			.el-card__body {
				padding: 0;
				height: 100%;
				display: flex;
			}
		}
		&_text {
			padding: 10px;
			box-sizing: border-box;
			display: flex;
			flex-flow: column;
			flex: 1;
		}
		&-info {
		}
		&-title {
			font-weight: bold;
			// cursor: pointer;
		}
		&-img {
			width: 150px;
			// cursor: pointer;
			.el-image {
                width: 100%;
                height: auto;
                vertical-align: top;
                @include thumb-square();
			}
		}
		&-desc {
			color: $txt-color-grey;
			margin-top: 5px;
            margin-bottom: auto;
		}
		&-chapter {
			margin-top: 10px;
			color: $color-primary;
		}
		&_progress {
			display: flex;
			color: $txt-color-grey;
			justify-content: space-between;
            align-items: center;
		}
	}
}
